import {
  ADD_STEP,
  ADD_WAITLIST_INTO_BOOKING_STEPS,
  CREATE_STATE,
  RESET_SEARCHED_LOCATION,
  SET_ACTIVE_STEP,
  SET_BOOKING,
  SET_CHANGE_LOCATION,
  SET_IS_ERROR,
  SET_LOCATION,
  SET_NEXT_STEP,
  SET_PERSONAL_DATA,
  SET_PREV_STEP,
  SET_SEARCHED_LOCATION,
  SET_STEP_VARIANT,
  SET_TIME,
  SET_USER_SELECTED_CITY,
  UPDATE_STATE,
} from 'components/Booking-new/actions';
import stepTypes from 'components/Booking-new/modules/Steps/types';

export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STEP_VARIANT: {
      const updatedSteps = state.allSteps.map(step => {
        if (step.type === action.payload.type)
          return {
            type: action.payload.type,
            variant: action.payload.variant,
            data: action.payload.data,
          };

        return step;
      });

      return {
        ...state,
        allSteps: [...updatedSteps],
      };
    }
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_NEXT_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_PREV_STEP:
      return {
        ...state,
        activeStep: action.payload,
        isError: false,
      };
    case ADD_STEP:
      return {
        ...state,
        allSteps: [
          ...state.allSteps,
          {
            type: action.payload.type,
            verison: action.payload.verison,
            data: action.payload.data,
          },
        ],
      };
    case SET_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case SET_PERSONAL_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.personalData]: {
            ...action.payload,
          },
        },
      };
    case ADD_WAITLIST_INTO_BOOKING_STEPS:
      return {
        ...state,
        allSteps: [...state.allSteps, state.waitlistStep],
      };
    case SET_TIME:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.time]: {
            time: action.payload,
          },
        },
      };
    case SET_BOOKING:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.summary]: {
            ...action.payload,
          },
        },
      };
    case SET_USER_SELECTED_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.location]: {
            ...state.userData[stepTypes.location],
            selectedLocation: action.payload,
            isSelected: true,
          },
        },
      };
    case SET_CHANGE_LOCATION:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.location]: {
            ...state.userData[stepTypes.location],
            isSelected: false,
          },
        },
      };
    case SET_SEARCHED_LOCATION:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.location]: {
            ...state.userData[stepTypes.location],
            searchedLocation: action.payload,
          },
        },
      };
    case RESET_SEARCHED_LOCATION:
      return {
        ...state,
        userData: {
          ...state.userData,
          [stepTypes.location]: {
            ...state.userData[stepTypes.location],
            searchedLocation: null,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
