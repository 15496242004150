const CREATE_STATE = 'CREATE_STATE';
const UPDATE_STATE = 'UPDATE_STATE';
const SET_STEP_VARIANT = 'SET_STEP_VARIANT';
const SET_NEXT_STEP = 'SET_NEXT_STEP';
const SET_PREV_STEP = 'SET_PREV_STEP';
const ADD_STEP = 'ADD_STEP';
const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';
const SET_TIME = 'SET_TIME';
const ADD_WAITLIST_INTO_BOOKING_STEPS = 'ADD_WAITLIST_INTO_BOOKING_STEPS';
const SET_IS_ERROR = 'SET_IS_ERROR';
const SET_USER_SELECTED_CITY = 'SET_USER_SELECTED_CITY';
const SET_LOCATION = 'SET_LOCATION';
const SET_BOOKING = 'SET_BOOKING';
const SET_SEARCHED_LOCATION = 'SET_SEARCHED_LOCATION';
const SET_CHANGE_LOCATION = 'SET_CHANGE_LOCATION';
const RESET_SEARCHED_LOCATION = 'RESET_SEARCHED_LOCATION';
const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';

export {
  CREATE_STATE,
  UPDATE_STATE,
  SET_STEP_VARIANT,
  SET_NEXT_STEP,
  SET_PREV_STEP,
  ADD_STEP,
  SET_PERSONAL_DATA,
  SET_TIME,
  ADD_WAITLIST_INTO_BOOKING_STEPS,
  SET_IS_ERROR,
  SET_USER_SELECTED_CITY,
  SET_LOCATION,
  SET_BOOKING,
  SET_SEARCHED_LOCATION,
  SET_CHANGE_LOCATION,
  RESET_SEARCHED_LOCATION,
  SET_ACTIVE_STEP,
};
