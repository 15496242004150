export const IS_PREVIEW =
  process.env.NODE_ENV === 'development' || process.env.BRANCH !== 'master' || process.env.GATSBY_IS_PREVIEW === 'true';

export const LOCATION_URL_ENUM = {
  type: {
    locationCode: 'locationCode',
    state: 'state',
    city: 'city',
    leadGenUrl: '/booking-v2-lead',
  },
  baseUrl: '/booking',
};
